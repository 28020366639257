<template>
  <v-navigation-drawer
    :value="isSidebarDataFilterActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    width="350"
    app
    @input="val => $emit('update:is-sidebar-data-filter-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center">
        <h4 class="font-weight-semibold text-base">
          {{ $t('lbl.filtros') }}
        </h4>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="cancelar"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text
        v-if="tabla"
        class="pt-5"
      >
        <v-form ref="stepForm">
          <v-row>
            <fragment v-if="tabla.tabla === 'cars'">
              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                  v-model="filtrosExport.marca_id"
                  :items="itemsMarcas"
                  :search-input.sync="searchMarca"
                  hide-details
                  hide-selected
                  :label="$t('menu.marca')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('menu.marca') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar v-if="item.icon !== null">
                      <v-img
                        size="20"
                        :src="rutaPublic + item.icon"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-avatar v-else>
                      <v-avatar
                        color="primary"
                        size="30"
                      >
                        <span class="white--text text-h5">{{ item.name.substr(0, 1) }}</span>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                      <v-list-item-subtitle></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-select
                  v-model="filtrosExport.transmision_id"
                  :items="transmisiones"
                  item-text="name"
                  item-value="id"
                  :label="$t('menu.trasnmision')"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                  v-model="filtrosExport.tipo_auto_id"
                  :items="itemsTypeAuto"
                  :search-input.sync="searchTypeAuto"
                  hide-details
                  hide-selected
                  :label="$t('menu.typeCar')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('menu.typeCar') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-select
                  v-model="filtrosExport.combustible_id"
                  :items="combustibles"
                  item-text="name"
                  item-value="id"
                  :label="$t('menu.combustible')"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="filtrosExport.cant_puertas"
                  :label="$t('lbl.cantPuertas')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  min="0"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="filtrosExport.cant_paxs"
                  :label="$t('lbl.cantPaxs')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  min="0"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="filtrosExport.cant_maletas_big"
                  :label="$t('lbl.cantMaletas')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  min="0"
                  :suffix="$t('lbl.big')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="filtrosExport.cant_maletas_small"
                  :label="$t('lbl.cantMaletas')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  min="0"
                  :suffix="$t('lbl.small')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-select
                  v-model="filtrosExport.caracteristicas_id"
                  :items="caracteristicas"
                  item-text="name"
                  item-value="id"
                  :label="$t('menu.caracteristicas')"
                  outlined
                  dense
                  hide-details
                  multiple
                ></v-select>
              </v-col>
            </fragment>
            <fragment v-if="tabla.tabla === 'hotels'">
              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                  v-model="filtrosExport.operador_id"
                  :items="itemsOperadores"
                  :search-input.sync="searchOperador"
                  hide-details
                  hide-selected
                  :label="$t('menu.operador')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  @change="setOperador()"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('menu.operador') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                  v-model="filtrosExport.marca_asociada_id"
                  :items="itemsMarcasHotels"
                  :search-input.sync="searchMarcasHotels"
                  hide-details
                  hide-selected
                  :label="$t('menu.marcaAsociada')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('menu.marcaAsociada') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-select
                  v-model="filtrosExport.categoria_id"
                  :items="categories"
                  item-text="name"
                  item-value="id"
                  :label="$t('menu.category')"
                  outlined
                  dense
                  hide-details
                >
                  <template v-slot:selection="{ item }">
                    <span v-if="item.name !== null">
                      {{ item.name }}
                      <span v-if="item.plus">PLUS</span>
                    </span>
                    <span v-if="item.name === null">
                      <v-row>
                        <v-col
                          cols="12"
                          md="8"
                        >
                          <v-rating
                            v-model="item.cant_estrellas"
                            color="primary"
                            size="30"
                            readonly
                          ></v-rating>
                        </v-col>
                        <v-col
                          cols="12"
                          md="4"
                          class="mt-2"
                        >
                          <span v-if="item.plus">PLUS</span>
                        </v-col>
                      </v-row>
                    </span>
                  </template>

                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title v-if="item.name !== null">
                        {{ item.name }}
                        <span v-if="item.plus">PLUS</span>
                      </v-list-item-title>
                      <v-list-item-title v-if="item.name === null">
                        <v-row>
                          <v-col
                            cols="12"
                            md="8"
                          >
                            <v-rating
                              v-model="item.cant_estrellas"
                              color="primary"
                              readonly
                              size="30"
                            ></v-rating>
                          </v-col>
                          <v-col
                            cols="12"
                            md="2"
                          >
                            <p
                              v-if="item.plus"
                              class="pt-3"
                            >
                              PLUS
                            </p>
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                  v-model="filtrosExport.tipo_destino_id"
                  :items="itemsTipoDestino"
                  :search-input.sync="searchTipoDestino"
                  hide-details
                  hide-selected
                  :label="$t('menu.typeDestination')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('menu.typeDestination') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                  v-model="filtrosExport.intereses_id"
                  :items="interesesHotel"
                  :label="$t('menu.intereses')"
                  outlined
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  multiple
                >
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                  v-model="filtrosExport.servicios_id"
                  :items="serviciosHotel"
                  :label="$t('menu.servicios')"
                  outlined
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  multiple
                >
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-switch
                      v-model="filtrosExport.only_adult"
                      :label="$t('lbl.only_adult')"
                      color="primary"
                      hide-details
                      class="pt-0 mt-0"
                      @click="setOnlyAdult"
                    ></v-switch>
                  </v-col>
                  <v-col
                    v-if="filtrosExport.only_adult"
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="filtrosExport.edad_min_id"
                      :items="edadesHotel"
                      item-text="name"
                      item-value="id"
                      :label="$t('menu.edadMin')"
                      outlined
                      dense
                      hide-details
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                  v-model="filtrosExport.rooms_id"
                  :items="roomsHotel"
                  :label="$t('lbl.room')"
                  outlined
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  multiple
                >
                </v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                  v-model="filtrosExport.country"
                  :items="countries"
                  :label="$t('lbl.country')"
                  outlined
                  dense
                  hide-details
                  item-text="country"
                  item-value="country"
                  multiple
                >
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                  v-model="filtrosExport.state"
                  :items="statesFree"
                  :label="$t('lbl.provinceEstado')"
                  outlined
                  dense
                  hide-details
                  multiple
                >
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                  v-model="filtrosExport.city"
                  :items="citiesFree"
                  :label="$t('lbl.locality')"
                  outlined
                  dense
                  hide-details
                  multiple
                >
                </v-autocomplete>
              </v-col>
            </fragment>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="error"
                block
                @click="cancelar"
              >
                {{ $t('btn.cancele') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="primary"
                outlined
                block
                :loading="loadingBtn"
                @click="$emit('search')"
              >
                {{ $t('btn.apply') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { mdiClose } from '@mdi/js'

export default {
  model: {
    prop: 'isSidebarDataFilterActive',
    event: 'update:is-sidebar-data-filter-active',
  },
  props: {
    isSidebarDataFilterActive: {
      type: Boolean,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    tabla: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    marcasCars: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    transmisiones: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    typeAuto: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    combustibles: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    caracteristicas: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    operadoresHotel: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    marcasHotel: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    categories: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    tiposDestinosHotel: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    interesesHotel: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    serviciosHotel: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    edadesHotel: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    roomsHotel: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    countries: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    states: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    statesList: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    cities: {
      type: Array,
    },
  },
  data() {
    return {
      stepForm: null,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      icons: {
        mdiClose,
      },
      textRequiered: [v => !!v || 'Campo requerido'],
      emailRules: [v => !!v || 'Campo requerido', v => /.+@.+/.test(v) || 'E-mail debe ser válido'],
      loading: false,
      search: {},
      itemsMarcas: [],
      searchMarca: null,
      itemsTypeAuto: [],
      searchTypeAuto: null,

      itemsOperadores: [],
      searchOperador: null,
      itemsMarcasHotels: [],
      searchMarcasHotels: null,
      itemsTipoDestino: [],
      searchTipoDestino: null,
    }
  },
  computed: {
    ...mapState({
      filtrosExport: state => state.app.filtrosExport,
      loadingBtn: state => state.app.loadingBtn,
    }),
    statesFree() {
      let result = []

      if (this.filtrosExport.country) {
        if (this.filtrosExport.country.length > 0) {
          this.filtrosExport.country.forEach(element => {
            if (this.countries.filter(e => e.country === element).length > 0) {
              const resultCCC = this.countries.filter(e => e.country === element)[0].states
              resultCCC.forEach(elementResultC => {
                result.push(elementResultC)
              })
            }
          })
        } else {
          result = this.statesList
        }
      } else {
        result = this.statesList
      }

      return result
    },
    citiesFree() {
      let result = []

      if (this.filtrosExport.state) {
        if (this.filtrosExport.state.length > 0) {
          this.filtrosExport.state.forEach(element => {
            if (this.states.filter(e => e.state === element).length > 0) {
              const resultCCC = this.states.filter(e => e.state === element)[0].cities
              resultCCC.forEach(elementResultC => {
                result.push(elementResultC)
              })
            }
          })
        } else {
          result = this.cities
        }
      } else {
        result = this.cities
      }

      return result
    },
  },
  watch: {
    // cars
    searchMarca(val) {
      if (val !== undefined && val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterMarca(val.toLowerCase())
      } else {
        this.itemsMarcas = []
      }
    },
    searchTypeAuto(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterTypeAuto(val.toLowerCase())
      } else {
        this.itemsTypeAuto = []
      }
    },

    // hotels
    searchOperador(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterOperador(val.toLowerCase())
      } else {
        this.itemsOperadores = []
      }
    },
    searchMarcasHotels(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterMarcasHotels(val.toLowerCase())
      }
    },
    searchTipoDestino(val) {
      // eslint-disable-next-line no-unused-expressions
      this.filterTipoDestino(val.toLowerCase())
    },
  },
  methods: {
    ...mapMutations(['setOnlyShow', 'setFiltrosExport']),

    // cars
    filterMarca(v) {
      this.itemsMarcas = []
      if (v === '') {
        this.itemsMarcas = []
      } else {
        this.itemsMarcas = this.marcasCars.filter(e => e.name.toLowerCase())
      }
    },
    filterTypeAuto(v) {
      this.itemsTypeAuto = []
      if (v === '') {
        this.itemsTypeAuto = []
      } else {
        this.itemsTypeAuto = this.typeAuto.filter(e => e.name.toLowerCase())
      }
    },

    // hotels
    filterOperador(v) {
      this.itemsOperadores = []
      if (v === '') {
        this.itemsOperadores = []
      } else {
        this.itemsOperadores = this.operadoresHotel.filter(e => e.name.toLowerCase())
      }
    },
    filterMarcasHotels() {
      if (this.filtrosExport.operador_id) {
        if (this.operadoresHotel.filter(e => e.id === this.filtrosExport.operador_id)[0].marcas.length > 0) {
          this.itemsMarcasHotels = this.operadoresHotel.filter(e => e.id === this.filtrosExport.operador_id)[0].marcas
        }
      } else {
        this.itemsMarcasHotels = this.marcasHotel.filter(e => e.name.toLowerCase())
      }
    },
    setOperador() {
      this.itemsMarcasHotels = []
      if (this.filtrosExport.operador_id) {
        if (this.operadoresHotel.filter(e => e.id === this.filtrosExport.operador_id)[0].marcas.length > 0) {
          this.itemsMarcasHotels = this.operadoresHotel.filter(e => e.id === this.filtrosExport.operador_id)[0].marcas
        }
      }
    },
    filterTipoDestino() {
      this.itemsTipoDestino = this.tiposDestinosHotel.filter(e => e.name.toLowerCase())
    },
    setOnlyAdult() {
      if (!this.filtrosExport.only_adult) {
        this.filtrosExport.edad_min_id = null
      }
    },

    cancelar() {
      this.setFiltrosExport({})
      this.$emit('update:is-sidebar-data-filter-active', false)
      this.$emit('search')
    },
  },
}
</script>
